.t-c-header {
    padding: 1rem 3rem;
}

.t-c-header h2 {
    color: var(--primary-orange);
}

.terms {
    padding: 1rem 3rem;
    color: #222;
    background: #FAFAFF;
    text-align: justify;
    font-family: "Montserrat";
    line-height: 2.6;
}

.terms p {
    font-size: 16px;
    line-height: 2.6;
}

.terms ul {
    font-size: 16px;
    line-height: 2.6;
}

.terms h4{
    margin-top: 35px;
}

.terms strong {
    text-decoration: underline;
    font-size: 16px;
    margin: .6rem 0;
}