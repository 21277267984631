.bg1 {
    background: url("../../Images/bg.webp");
    background-size: cover;
    padding: .5rem 0;
    margin-bottom: 2rem;
}

.bg2 {
    background: url("../../Images/bg-2.webp");
    background-size: cover;
    padding: .5rem 0;
    color: var(--white-text);
    margin-top: 2rem;
}

.hero-section {
    height: 90vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
}

.big-text {
    font-size: 4rem;
    font-family: 'Chonburi', cursive;
}

.primary-color-text {
    color: var(--primary-orange);
}

.hero-text p {
    font-size: 16px;
}

.hero-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.btns {
    width: 62%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.download-btn {
    background: url("../../Images/bg-2.webp");
    background-size: cover;
    width: 10rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0.3rem;
    border: 1px solid var(--primary-orange);
    box-shadow: 0px 0px 3px var(--primary-orange);
    border-radius: 1.5rem;
}

.download-btn:hover {
    transform: scale(1.05);
}

.download-btn a {
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--white-text);
}

.btn-icon {
    font-size: 1.7rem;
}

.btn-text {
    width: 90%;
    font-size: 0.7rem;
    margin-left: .4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.btn-text span {
    display: block;
}

.feature-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80vh;
    margin: 2rem 0;
}

.feature-text {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    font-family: "Open Sans", sans-serif;
}

.feature-text p {
    font-size: 14px;
}

.feature-img,
.feature-video {
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature-img img {
    width: 23rem;
    height: auto;
    object-fit: contain;
}

.feature-video img {
    margin-top: 1rem;
}

.feature-bold-text {
    font-weight: 700;
    font-size: 1.87rem;
}

.feature-ul-list {
    list-style: square;
    font-weight: 600;
    font-size: 1.25rem;
    width: 80%;
}

.feature-ul-list li::marker {
    color: var(--primary-orange);
    font-size: 2rem;
}

.feature-section-2 {
    margin-top: 4rem;
}

.feature-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feature-desc:nth-of-type(even) {
    flex-direction: row-reverse;
}

.feature-desc-text {
    width: 80%;
    margin: 0 2rem;
}

.feature-desc-img {
    width: 100%;
}

.feature-desc-img img {
    width: 90%;
    height: auto;
}

.grey-colored-text {
    color: #444;
    margin-top: 1rem;
}

.resize-img {
    transform: rotateX(27deg);
}

.text-right {
    text-align: right;
}

.gradient-container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: var(--white-text);
    padding: 1rem 2rem;
}

.feature-card {
    width: 14rem;
    height: 9rem;
    background-color: #ffffff26;
    backdrop-filter: blur(7px);
    border-radius: 1.6rem;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.4rem 1rem;
    font-size: 16px;
}

.feature-card img {
    width: 3rem;
    height: auto;
    object-fit: contain;
}

.safe-mode {
    margin-top: 1rem;
}

@media screen and (max-width: 400px) {
    .btns {
        width: 80%;
        flex-direction: column;
        align-items: flex-start;
    }
    .big-text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 800px) {
    .bg1 {
        background-size: stretch;
        margin-bottom: 2rem;
    }
    .bg2 {
        background-size: stretch;
        margin-top: 2rem;
    }
    .hero-section {
        height: 100%;
        margin-bottom: 1rem;
    }
    .hero-img {
        /* display: none; */
        margin-top: 1rem;
    }
    .big-text {
        font-size: 3rem;
    }
    .btns {
        width: 80%;
    }
    .row {
        flex-direction: column;
    }
    .col {
        margin: 1rem 0;
    }
    .feature-section {
        margin: 2rem 0;
        flex-direction: column;
    }
    .reverse {
        flex-direction: column-reverse;
    }
    .feature-text {
        margin-bottom: .5rem;
        margin-top: .3rem;
    }
    .text-right {
        text-align: center;
    }
    .feature-img img {
        width: 10rem;
    }
    .gradient-container {
        flex-direction: column;
    }
    .feature-desc {
        flex-direction: column;
        margin: 1rem 0;
    }
    .feature-desc:nth-of-type(even) {
        flex-direction: column;
    }
    .feature-desc-text {
        width: 100%;
    }
    .feature-desc-img {
        width: 100%;
    }
    .feature-desc-img img {
        width: 100%;
    }
    .feature-video img {
        width: 100%;
        margin: 0;
    }
}