.contact-header {
    text-align: center;
    margin-top: 2rem;
    font-family: "Open Sans", sans-serif;
}

.contact-header h2 {
    color: var(--primary-orange);
    font-weight: 700;
}

.contact-header p {
    font-size: 1.2rem;
    margin-top: 1rem;
}

.contact-info {
    background: url("../../Images/bg-2.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    color: var(--white-text);
}

.contact-info p {
    font-size: 1rem;
}

.contact-form {
    padding: 4rem;
    background: url("../../Images/bg.webp");
    background-position: center;
    background-size: stretch;
}

.form-label {
    margin-top: .6rem;
    margin-bottom: .1rem;
}

.no-display {
    display: none;
}

.success-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.success-message p {
    font-size: 16px;
}

button.form-control {
    margin: 1rem 0;
}


/* .form-control:focus {
    box-shadow: 0 0 0 -3px rgba(255, 102, 0, 0.631);
    border: 1px solid rgba(255, 102, 0, 0.631);
} */

@media screen and (max-width:800px) {
    .contact-form {
        margin: .5rem 0;
    }
}