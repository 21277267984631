.footer-top {
    padding: 2rem 8rem;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: #ff66003f;
}

.footer-bottom {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.brand-summary img {
    width: 4rem;
    height: auto;
    object-fit: cover;
    margin-bottom: 1rem;
}

.footer-text {
    width: 70%;
}

.footer-btns .download-btn {
    background: var(--white-text);
}

.footer-btns .download-btn a {
    color: var(--primary-orange);
}

.footer-btns .download-btn a .btn-text {
    color: #000;
}

.footer-nav,
.social-links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.footer-nav p {
    width: 25%;
}

.footer-link-list {
    list-style: none;
    width: 30%;
}

.footer-link-list li {
    cursor: pointer;
}

.footer-link-list li a {
    text-decoration: none;
    color: var(--white-text);
}

.social-link-list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}

.social-link-list li a {
    color: var(--white-text);
    text-decoration: none;
    font-size: 1.25rem;
}

@media screen and (max-width: 800px) {
    .footer-top {
        padding: 1rem;
    }
    .footer-btns .download-btn {
        margin-left: 0;
    }
    .footer-nav,
    .social-links {
        align-items: flex-start;
    }
    .footer-nav p,
    .footer-link-list {
        width: 100%;
    }
    .footer-link-list li,
    .social-link-list li {
        margin-left: -2rem;
    }
}