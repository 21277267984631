.faq-column {
    text-align: justify;
    margin: 1rem 0;
}

.faq-column span.primary-color-text {
    margin-right: 1rem;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.6;
}

.faq-header {
    color: var(--primary-orange);
    margin: 1rem 0;
}

.faqs {
    font-family: "Open Sans", sans-serif;
    padding: 1rem 3rem;
    background-color: #FAFAFF;
    color: #222;
    line-height: 2.6;
}