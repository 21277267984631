@import url("https://fonts.googleapis.com/css2?family=Chonburi&family=Dancing+Script&family=Montserrat:wght@300;400;500&family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
:root {
    --primary-orange: #f76e06;
    --grey: #fafaff;
    --dark-color: #000;
    --white-text: #fff;
}

* {
    border: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: "Open Sans", sans-serif;
    position: relative;
    width: 100%;
}
.logo-text {
    font-size: 9px;
    font-style: italic;
    margin-top: -3px;
}
.toggle-icon {
    position: sticky;
    top: 50%;
    left: 4%;
}

nav a.nav-link {
    font-weight: 500;
    letter-spacing: 0.04rem;
    color: #222 !important;
}

nav a.nav-link:hover,
nav a.nav-link.active:focus {
    color: var(--primary-orange) !important;
    border: none !important;
}

.navbar-toggler {
    border: none !important;
    color: var(--primary-orange) !important;
}

.navbar-toggler:focus {
    border: none !important;
}

p,
li {
    font-size: 14px;
}

.messenger-icon {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 8px;
}

@media screen and (max-width: 800px) {
    .toggle-icon {
        z-index: 9;
    }
    .bodyContainer {
        width: 100%;
    }
}
