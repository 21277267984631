.fof {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 0;
}

.fofmessage {
    display: flex;
    flex-direction: column;
    color: #333;
}

.fofmessage h2 {
    font-size: 4rem;
    color: var(--primary-orange);
    margin: 1rem 0;
    letter-spacing: .2rem;
}

.fofmessage h5 {
    font-size: 1.5rem;
    margin: 1rem 0;
}

.fofmessage button {
    align-self: center;
    margin-top: 2rem;
}